import { Invoicer, Order, OrderDetailed, OrderDiscount } from './../models/models'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { end_loader, loader } from 'src/app/utilities/loader/loader.utilities'
import { environment } from 'src/environments/environment'
import {
	ApiCallback,
	APIError,
	ApiResponse,
	CommerceData,
	CommerceDepositData,
	DepositImageData,
	OperationAdjustmentData,
	TerminalConfigurationData,
	UpdateContactCommerceRequest,
	UserData,
} from '../models/api.models'
import {
	Acquirer,
	Agency,
	AgencyDetailed,
	ClientContract,
	CommerceDetailed,
	CommerceInfo,
	CommerceOperation,
	Intermediary,
	InvoicerInfo,
	Lot,
	LotDetailed,
	PaymentMethod,
	Role,
	TerminalConfiguration,
	Transaction,
	UserInfo,
	UserDetailed,
	User,
	AgencyInvoicer,
	CommerceOperationDetails,
	CommerceMovement,
	UserCreated,
	UserChangePassword,
	Permissions,
} from '../models/models'
import { State } from '../state.shared'

const api = environment.api
let router: Router

@Injectable()
export class DataService {
	private get headers(): { [key: string]: string } {
		return State.auth_token
			? {
					Authorization: State.auth_token,
			  }
			: {}
	}

	constructor(private http: HttpClient, r: Router) {
		router = r
	}

	private get<T>(url: string, callback: ApiCallback<T>) {
		loader()
		environment.debug(`get request to ${url}`)
		this.http.get<ApiResponse<T>>(url, { headers: this.headers }).subscribe(handle_success(url, callback), handle_error(url, callback))
	}

	private post<T, D>(url: string, body: D, callback: ApiCallback<T>) {
		loader()
		environment.debug(`post request to ${url}:`, body)
		this.http.post<ApiResponse<T>>(url, body, { headers: this.headers }).subscribe(handle_success(url, callback), handle_error(url, callback))
	}

	private delete<T, D>(url: string, callback: ApiCallback<T>) {
		loader()
		environment.debug(`delete request to ${url}:`)
		this.http.delete<ApiResponse<T>>(url, { headers: this.headers }).subscribe(handle_success(url, callback), handle_error(url, callback))
	}

	login(username: string, password: string, callback: ApiCallback<{ user: UserInfo; token: string }>) {
		this.post(api('login'), { username, password }, callback)
	}

	authenticate(callback: ApiCallback<UserInfo>) {
		this.get(api('authenticate'), callback)
	}

	transactions_by_client_reference(client_reference: string, callback: ApiCallback<Transaction[]>) {
		this.get(api('transactions', 'client', client_reference), callback)
	}

	commerces(callback: ApiCallback<CommerceInfo[]>) {
		this.get(api('commerces'), callback)
	}

	commerce_detailed(id: string, callback: ApiCallback<CommerceDetailed>) {
		this.get(api('commerce', id), callback)
	}


	async transactions_by_commerce(commerce_id: number, date_start: string | Date, date_end: string | Date): Promise<Transaction[]> {
		return new Promise<Transaction[]>((resolve, reject) => {
		  const callback: ApiCallback<Transaction[]> = (response) => {
			if (response.succeeded) {
			  resolve(response.data);
			} else {
			  reject(new Error(response.error.message));
			}
		  };
	  
		  this.get(api('transactions', 'commerce', commerce_id) + query({ date_start, date_end }), callback);
		});
	}

	open_lots(callback: ApiCallback<Lot[]>) {
		this.get(api('lots', 'open'), callback)
	}

	closed_lots(agency_id: number, commerce_id: number, date_start: string | Date, date_end: string | Date, callback: ApiCallback<Lot[]>) {
		this.get(api('lots', 'closed') + query({ agency_id, commerce_id, date_start, date_end }), callback)
	}

	agencies(callback: ApiCallback<Agency[]>) {
		this.get(api('agencies'), callback)
	}

	roles(callback: ApiCallback<Role[]>) {
		this.get(api('roles'), callback)
	}

	user_agencies(callback: ApiCallback<Agency[]>) {
		this.get(api('user', 'agencies'), callback)
	}

	agency_commerces(agency_id: number, callback: ApiCallback<CommerceInfo[]>) {
		this.get(api('commerces', 'agency', agency_id), callback)
	}

	transactions_by_commerces(commerce_ids: number[], date_start: string | Date, date_end: string | Date, callback: ApiCallback<Transaction[]>) {
		this.post(api('transactions', 'commerces'), { commerce_ids, date_start, date_end }, callback)
	}

	users(callback: ApiCallback<User[]>) {
		this.get(api('users'), callback)
	}

	users_detailed(callback: ApiCallback<UserDetailed[]>) {
		this.get(api('users', 'detailed'), callback)
	}

	user_change_enable(userid: string, enable: boolean, callback: ApiCallback<boolean>) {
		this.post(api('user', 'enable'), { userid, enable }, callback)
	}

	user_change_password(data: UserChangePassword, callback: ApiCallback<null>) {
		this.post(api('user', 'change_password'), data, callback)
	}
	user_commerces(user_id: number, callback: ApiCallback<CommerceInfo[]>) {
		this.get(api('commerces', 'user', user_id), callback)
	}

	commerces_operation(callback: ApiCallback<CommerceOperation[]>) {
		this.get(api('commerces', 'operation'), callback)
	}

	commerce_portfolio(commerce_id: number, agency_id: number, callback: ApiCallback<ClientContract[]>) {
		this.get(api('commerce', agency_id, 'portfolio', commerce_id), callback)
	}

	agencies_detailed(callback: ApiCallback<AgencyDetailed[]>) {
		this.get(api('agencies', 'detailed'), callback)
	}

	invoicers(callback: ApiCallback<InvoicerInfo[]>) {
		this.get(api('invoicers'), callback)
	}

	payment_methods(callback: ApiCallback<PaymentMethod[]>) {
		this.get(api('payment_methods'), callback)
	}

	invoicer_payment_methods(invoicer_id: number, callback: ApiCallback<PaymentMethod[]>) {
		this.get(api('invoicer', invoicer_id, 'payment_methods'), callback)
	}

	permission(callback: ApiCallback<Permissions[]>) {
		this.get(api('permissions'), callback)
	}

	terminal_configurations(callback: ApiCallback<TerminalConfiguration[]>) {
		this.get(api('terminal_configurations'), callback)
	}

	acquirers(callback: ApiCallback<Acquirer[]>) {
		this.get(api('acquirers'), callback)
	}

	lot_detailed(mode: 'postpaid' | 'prepaid', id: number, callback: ApiCallback<LotDetailed>) {
		this.get(api('lot', mode, id), callback)
	}

	intermediaries(callback: ApiCallback<Intermediary[]>) {
		this.get(api('intermediaries'), callback)
	}

	agency_invoicers(callback: ApiCallback<AgencyInvoicer[]>) {
		this.get(api('agency', 'invoicers'), callback)
	}

	commerce_operation(commerce_id: number, callback: ApiCallback<CommerceOperationDetails>) {
		this.get(api('commerce', commerce_id, 'operation'), callback)
	}

	commerce_movements(commerce_id: number, date_start: string | Date, date_end: string | Date, callback: ApiCallback<CommerceMovement[]>) {
		this.get(api('commerce', commerce_id, 'movements') + query({ date_start, date_end }), callback)
	}

	retrieve_operation_resource(commerce_id: number, resource_id: string, callback: ApiCallback<{ filename: string; dataurl: string }>) {
		this.get(api('commerce', commerce_id, 'operation', 'resource', resource_id), callback)
	}

	assign_user_commerces(user_id: number, commerce_ids: number[], callback: ApiCallback<null>) {
		this.post(api('user', user_id, 'commerces'), { commerce_ids }, callback)
	}

	create_agency(data: AgencyDetailed, callback: ApiCallback<number>) {
		this.post(api('agency'), data, callback)
	}

	edit_agency(agency_id: number, data: AgencyDetailed, callback: ApiCallback<null>) {
		this.post(api('agency', agency_id), data, callback)
	}

	assign_payment_methods_to_invoicer(invoicer_id: number, payment_method_ids: number[], callback: ApiCallback<null>) {
		this.post(api('invoicer', invoicer_id, 'payment_methods'), { payment_method_ids }, callback)
	}

	create_commerce(data: CommerceData, callback: ApiCallback<{ id: number; code: string }>) {
		this.post(api('commerce'), data, callback)
	}

	edit_commerce(commerce_id: number, data: CommerceData, callback: ApiCallback<null>) {
		this.post(api('commerce', commerce_id), data, callback)
	}

	active_commerce(commerce_id: number, data: boolean, callback: ApiCallback<null>) {
		this.post(api('commerce', 'active', commerce_id), { enabled: data }, callback)
	}

	edit_contact_commerce(commerce_id: number, data: UpdateContactCommerceRequest, callback: ApiCallback<null>) {
		this.post(api('commerce', commerce_id, 'contact'), data, callback)
	}

	create_user(data: UserData, callback: ApiCallback<{ password: string; user_data: UserDetailed }>) {
		this.post(api('user'), data, callback)
	}

	edit_user(user_id: number, data: UserData, callback: ApiCallback<UserDetailed>) {
		this.post(api('user', user_id), data, callback)
	}

	generate_password_user(user_id: number, callback: ApiCallback<{ password: string; user_data: UserDetailed }>) {
		this.get(api('user', user_id, 'generate_password'), callback)
	}

	create_terminal_configuration(data: TerminalConfigurationData, callback: ApiCallback<null>) {
		this.post(api('terminal_configuration'), data, callback)
	}

	edit_terminal_configuration(configuration_id: number, data: TerminalConfigurationData, callback: ApiCallback<null>) {
		this.post(api('terminal_configuration', configuration_id), data, callback)
	}

	update_deposit_image(commerce_id: number, sequence: number, data: DepositImageData, callback: ApiCallback<null>) {
		this.post(api('commerce', commerce_id, 'deposit', sequence), data, callback)
	}

	register_deposit(commerce_id: number, data: CommerceDepositData, callback: ApiCallback<null>) {
		this.post(api('commerce', commerce_id, 'deposit'), data, callback)
	}

	delete_movement(commerce_id: number, sequence: number, callback: ApiCallback<null>) {
		this.get(api('commerce', commerce_id, 'deposit', sequence, 'delete'), callback)
	}

	register_adjustment(commerce_id: number, data: OperationAdjustmentData, callback: ApiCallback<null>) {
		this.post(api('commerce', commerce_id, 'adjustment'), data, callback)
	}

	nullify_transaction(id: string, reason: string, callback: ApiCallback<null>) {
		this.post(api('transaction', id, 'nullify'), { reason }, callback)
	}

	excecute_close_lote(id: number, callback: ApiCallback<null>) {
		this.get(api('close', id, 'lot'), callback)
	}

	orders(callback: ApiCallback<Order[]>) {
		this.get(api('order', 'all'), callback)
	}

	order(id: string, callback: ApiCallback<OrderDetailed>) {
		this.get(api('order', id), callback)
	}

	apply_order_discount(id: string, amount: number, reference: string, observation: string, callback: ApiCallback<OrderDiscount>) {
		this.post(api('order', id, 'discount'), { amount, reference, observation }, callback)
	}

	order_discounts(callback: ApiCallback<OrderDiscount[]>) {
		this.get(api('order', 'discount', 'all'), callback)
	}

	remove_order_discount(id: number, callback: ApiCallback<null>) {
		this.post(api('order', 'discount', id, 'remove'), null, callback)
	}
}

function handle_success<T>(url: string, callback: ApiCallback<T>): ApiCallback<T> {
	return (response: ApiResponse<T>) => {
		end_loader()
		if (response.succeeded) environment.debug(`response from ${url}:`, response.data)
		else environment.error(`failure from ${url}:`, response.error)
		if (response.error?.code == 'NOT_AUTHENTICATED') router.navigate(['login'])
		else callback(response)
	}
}

function handle_error<T>(url: string, callback: ApiCallback<T>): (error: HttpErrorResponse) => void {
	return (e: HttpErrorResponse) => {
		end_loader()
		const error = e.error?.error
		if (error) {
			environment.error(`failure from ${url}:`, error)
			if (error.code == 'NOT_AUTHENTICATED') router.navigate(['login'])
			else callback(APIError(error))
		} else {
			environment.error(`error on request to ${url}:`, e)
			callback(
				APIError({
					code: 'UNKNOWN',
					message: e.error?.error?.message ?? e.statusText,
				})
			)
		}
	}
}

function query(obj: { [key: string]: any }): string {
	return (
		'?' +
		Object.keys(obj)
			.map((k) => `${k}=${obj[k]}`)
			.join('&')
	)
}
